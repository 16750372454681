import React, { useEffect } from 'react';
import { IndexComponent } from '..';
import SiteLayoutEmbeded from '../../components/SiteLayoutEmbeded';
import qs from 'query-string';

function Index() {

    useEffect(() => {
        check()
    }, []);
    async function check() {
        const parsed = qs.parse(location.search);
    }
    return (
        <SiteLayoutEmbeded>
            <IndexComponent isEmbeded={true} />
        </SiteLayoutEmbeded>
    );
}

export default Index;
